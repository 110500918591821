<template>
    <div class="list-container">
      <error :errors="settingError" />
      <b-overlay rounded="sm" :show="settingBusy">
        <portlet :title="title" foot-class="d-flex gap-10">
          <template v-slot:toolbar>
            <button @click="refreshList" v-b-tooltip.hover  title="يېڭىلاش" class="btn btn-clean btn-sm btn-icon btn-icon-md">
              <i class="flaticon2-refresh-arrow"></i>
            </button>
            <!--<b-button :to="{name: 'setting.create'}" class="btn btn-label-success btn-sm border-0">
              يېڭىدىن قوشۇش
            </b-button>-->
          </template>
          <template v-slot:body>
            <template v-for="setting in settings">
              <template v-if="setting.type === 'input' ">
                <b-row>
                  <b-col sm="6" md="12">
                    <b-form-group
                            :id="`${setting.name}_label`"
                            :label="setting.title"
                            :label-for="setting.name"
                            :description="setting.remark"
                    >
                      <b-form-input
                              :id="setting.name"
                              size="md"
                              v-model="formData[setting.name]"
                              type="text"
                              placeholder=" تەڭشەك قىممىتىنى كىرگۈزۈڭ"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
              <template v-if="setting.type === 'textarea' ">
                <b-row>
                  <b-col sm="6" md="12">
                    <b-form-group
                            :id="`${setting.name}_label`"
                            :label="setting.title"
                            :label-for="setting.name"
                            :description="setting.remark"
                    >
                      <b-form-textarea
                              :id="setting.name"
                              size="md"
                              v-model="formData[setting.name]"
                              type="text"
                              placeholder=" تەڭشەك قىممىتىنى كىرگۈزۈڭ"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
              <template v-if="setting.type === 'image' ">
                <b-row>
                  <b-col sm="6" md="4">
                    <b-form-group
                            :id="`${setting.name}_label`"
                            :label="setting.title"
                            :label-for="setting.name"
                            :description="setting.remark"
                    >
                      <image-uploader
                              :id="setting.name"
                              :check-size="true"
                              :label="setting.title"
                              v-model="formData[setting.name]"
                              :width="parseInt(setting.width)"
                              :height="parseInt(setting.height)"
                              :upload-form="{strategy: 'setting'}"
                              accepted-file-types="image/jpeg, image/png, image/jpg, image/gif"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
              <template v-if="setting.type === 'radio' ">
                <b-row>
                  <b-col sm="6" md="12">
                    <b-form-group
                            :id="`${setting.name}_label`"
                            :label="setting.title"
                            :label-for="setting.name"
                            :description="setting.remark"
                    >
                      <b-form-radio-group :id="setting.name" v-model="formData[setting.name]" :options="setting.source" buttons name="radios-btn-default" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
              <template v-if="setting.type === 'checkbox' ">
                <b-row>
                  <b-col sm="6" md="12">
                    <b-form-group
                            :id="`${setting.name}_label`"
                            :label="setting.title"
                            :label-for="setting.name"
                            :description="setting.remark"
                    >
                      <b-form-checkbox-group :id="setting.name" v-model="formData[setting.name]" :options="setting.source" buttons name="radios-btn-default" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
              <template v-if="setting.type === 'divider'">
                <b-row v-if="formData[setting.name]" class="mt-4 mb-2">
                  <b-col sm="6" md="12">
                    <divider :title="formData[setting.name]" />
                  </b-col>
                </b-row>
              </template>
            </template>
            <template v-if="settings.length < 1 ">
              <p class="text-center"> ھازىرچە تەڭشەك يوقكەن </p>
            </template>
          </template>
          <template v-slot:foot v-if="settings">
            <b-button id="submit" @click="onSubmit" variant="primary">
              ساقلاش
            </b-button>
            <b-button variant="danger">
              بىكار قىلىش
            </b-button>
          </template>
        </portlet>
      </b-overlay>
    </div>
</template>

<script>
import BreadCrumbs from "@c@/breadcrumbs.service";
import Error from "../../../partials/widgets/Error";
import Portlet from "../../../partials/content/Portlet";
import ImageUploader from "../../../partials/layout/ImageUploader";
import Divider from "../../../partials/widgets/Divider";
import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
import settingMixin from "@m@/setting.mixin";
import formBusyMixin from "@m@/common/formBusy.mixin";

export default {
  name: "index",
  components: { Error, Portlet, ImageUploader, Divider },
  mixins: [ sweetAlertMixin, settingMixin, formBusyMixin ],
  mounted() {
    BreadCrumbs.setBreadCrumb([]);
    BreadCrumbs.addBreadCrumb({title: "تەڭشەك باشقۇرۇش", route: {name: 'setting.home'}});
    BreadCrumbs.addBreadCrumb({ title: "بارلىق تەڭشەكلەر" });
  },
  created() {
    this.getSettings()
      .then(()=>{
        this.formatSettings();
      })
  },
  data(){
    return {
      title: 'بارلىق تەڭشەكلەر',
      settingBusy: false,
      formData: {},
      preview: {}
    }
  },
  methods: {
    formatSettings(){
      let formData = {};
      let preview = {};
      this.settings.forEach((item)=>{
        if ( item.type === 'checkbox' ) {
          Object.assign(formData, {[item.name]: item.json_value});
        }else {
          Object.assign(formData, {[item.name]: item.value});
        }
        if ( item.type === 'image' ) {
          Object.assign(preview, {[item.name]: item.image_preview});
        }
      });
      this.formData = formData;
      this.preview = preview;
    },
    onSubmit(){
      this.formBusy = true;
      this.updateSettings(this.formData)
        .then(()=>{
          this.success({ message: 'تەڭشەك يېڭىلاش مۇۋاپىقىيەتلىك بولدى'});
        })
    },
    handleEditorContent({content, id: name}){
      this.formData[name] = content;
    },
    refreshList(){
      this.getSettings()
        .then(()=>{
          this.formatSettings();
        })
    }
  }
}
</script>

<style scoped>

</style>
